<template>
    <router-view/>
</template>

<script>
  import { pcMixin } from '@/mixins/pcMixin.js';

  export default {
    name: 'AppView',

    mixins: [ pcMixin ],

    created() {
      // If the user is not logged in, go to the Sign In view.
      if (!this.pcIsLoggedIn()) {
        this.$router.push({ name: 'SignInRoute' })
      }
    },
  }
</script>
